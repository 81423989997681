/* body{
  font-family: Arial, Helvetica, sans-serif;
} */
/* @import url('https://fonts.googleapis.com/css?family=Bungee+Inline');
body{
  font-family: 'Bungee Inline', cursive;
} */
/* 
* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
} */
/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');



*/



@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@200&display=swap');
html,body{
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgb(245,245,245);
}