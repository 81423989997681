body{
    padding:0;
    margin:0;
    width:100%;
    height:100vh;
    background:white;
}
.wrapper{
    width:500px;
    height:60px;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
}
.circle{
    width:20px;
    height:20px;
    position: absolute;
    border-radius: 50%;
    background-color: rgb(9, 191, 247);
    left:15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
}

@keyframes circle{
    0%{
        top:60px;
        height:5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40%{
        height:20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100%{
        top:0%;
    }
}
.circle:nth-child(2){
    left:45%;
    animation-delay: .2s;
}
.circle:nth-child(3){
    left:auto;
    right:15%;
    animation-delay: .3s;
}
.shadow{
    width:20px;
    height:4px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    position: absolute;
    top:62px;
    transform-origin: 50%;
    z-index: -1;
    left:15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
}

@keyframes shadow{
    0%{
        transform: scaleX(1.5);
    }
    40%{
        transform: scaleX(1);
        opacity: .7;
    }
    100%{
        transform: scaleX(.2);
        opacity: .4;
    }
}
.shadow:nth-child(4){
    left: 45%;
    animation-delay: .2s
}
.shadow:nth-child(5){
    left:auto;
    right:15%;
    animation-delay: .3s;
}
 .wrapper span{
    position: relative;
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    letter-spacing: 6px;
    color: rgb(9, 156, 241);
    /* top:50%;
    left:50%;
    transform: translate(-50%, -50%); */

  }
.spanBlock{
  width:100%;
  display:flex;
  justify-content: center;
  flex-direction:row-reverse;
  margin-top: 100px;
}






/*  footer   */
footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida  Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
footer .fa-heart{
    color: red;
}
footer .fa-dev{
    color: #fff;
}
footer .fa-twitter-square{
  color:#1da0f1;
}
footer .fa-instagram{
  color: #f0007c;
}
fotter .fa-linkedin{
  color:#0073b1;
}
footer .fa-codepen{
  color:#fff
}
footer a {
    color: #3c97bf;
    text-decoration: none;
  margin-right:5px;
}
.youtubeBtn{
    position: fixed;
    left: 50%;
  transform:translatex(-50%);
    bottom: 45px;
    cursor: pointer;
    transition: all .3s;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
}
.youtubeBtn i{
   font-size:20px;
  float:left;
}
.youtubeBtn a{
    color:#ff0000;
    animation: youtubeAnim 1000ms linear infinite;
  float:right;
}
.youtubeBtn a:hover{
  color:#c9110f;
  transition:all .3s ease-in-out;
  text-shadow: none;
}
.youtubeBtn i:active{
  transform:scale(.9);
  transition:all .3s ease-in-out;
}
.youtubeBtn span{
    font-family: 'Lato';
    font-weight: bold;
    color: #fff;
    display: block;
    font-size: 12px;
    float: right;
    line-height: 20px;
    padding-left: 5px;
  
}

@keyframes youtubeAnim{
  0%,100%{
    color:#c9110f;
  }
  50%{
    color:#ff0000;
  }
}




















/* body {
    background:white;
  }
  
 .load {
    position:absolute;
    width:600px;
    height:36px;
    left:50%;
    top:40%;
    margin-left:-300px;
    overflow:visible;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    cursor:default;
  }
  
 .load div {
    position:absolute;
    width:20px;
    height:36px;
    opacity:0;
    font-family:Helvetica, Arial, sans-serif;
    animation:move 2s linear infinite;
    -o-animation:move 2s linear infinite;
    -moz-animation:move 2s linear infinite;
    -webkit-animation:move 2s linear infinite;
    transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    color:#06a2eb;
  }
  
 .load div:nth-child(2) {
    animation-delay:0.2s;
    -o-animation-delay:0.2s;
    -moz-animation-delay:0.2s;
    -webkit-animation-delay:0.2s;
  }
 .load div:nth-child(3) {
    animation-delay:0.4s;
    -o-animation-delay:0.4s;
    -webkit-animation-delay:0.4s;
    -webkit-animation-delay:0.4s;
  }
 .load div:nth-child(4) {
    animation-delay:0.6s;
    -o-animation-delay:0.6s;
    -moz-animation-delay:0.6s;
    -webkit-animation-delay:0.6s;
  }
 .load div:nth-child(5) {
    animation-delay:0.8s;
    -o-animation-delay:0.8s;
    -moz-animation-delay:0.8s;
    -webkit-animation-delay:0.8s;
  }
 .load div:nth-child(6) {
    animation-delay:1s;
    -o-animation-delay:1s;
    -moz-animation-delay:1s;
    -webkit-animation-delay:1s;
  }
 .load div:nth-child(7) {
    animation-delay:1.2s;
    -o-animation-delay:1.2s;
    -moz-animation-delay:1.2s;
    -webkit-animation-delay:1.2s;
  }
  
  @keyframes move {
    0% {
      left:0;
      opacity:0;
    }
    35% {
      left: 41%; 
      -moz-transform:rotate(0deg);
      -webkit-transform:rotate(0deg);
      -o-transform:rotate(0deg);
      transform:rotate(0deg);
      opacity:1;
    }
    65% {
      left:59%; 
      -moz-transform:rotate(0deg); 
      -webkit-transform:rotate(0deg); 
      -o-transform:rotate(0deg);
      transform:rotate(0deg); 
      opacity:1;
    }
    100% {
      left:100%; 
      -moz-transform:rotate(-180deg); 
      -webkit-transform:rotate(-180deg); 
      -o-transform:rotate(-180deg); 
      transform:rotate(-180deg);
      opacity:0;
    }
  }
  
  @-moz-keyframes move {
    0% {
      left:0; 
      opacity:0;
    }
    35% {
      left:41%; 
      -moz-transform:rotate(0deg); 
      transform:rotate(0deg);
      opacity:1;
    }
    65% {
      left:59%; 
      -moz-transform:rotate(0deg); 
      transform:rotate(0deg);
      opacity:1;
    }
    100% {
      left:100%; 
      -moz-transform:rotate(-180deg); 
      transform:rotate(-180deg);
      opacity:0;
    }
  }
  
  @-webkit-keyframes move {
    0% {
      left:0; 
      opacity:0;
    }
    35% {
      left:41%; 
      -webkit-transform:rotate(0deg); 
      transform:rotate(0deg); 
      opacity:1;
    }
    65% {
      left:59%; 
      -webkit-transform:rotate(0deg); 
      transform:rotate(0deg); 
      opacity:1;
    }
    100% {
      left:100%;
      -webkit-transform:rotate(-180deg); 
      transform:rotate(-180deg); 
      opacity:0;
    }
  }
  
  @-o-keyframes move {
    0% {
      left:0; 
      opacity:0;
    }
    35% {
      left:41%; 
      -o-transform:rotate(0deg); 
      transform:rotate(0deg); 
      opacity:1;
    }
    65% {
      left:59%; 
      -o-transform:rotate(0deg); 
      transform:rotate(0deg); 
      opacity:1;
    }
    100% {
      left:100%; 
      -o-transform:rotate(-180deg); 
      transform:rotate(-180deg); 
      opacity:0;
    }
  } */